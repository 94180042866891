.grid-container {
  display: grid;
  column-gap: 50px;
  row-gap: 50px;
  grid-template-columns: auto auto auto;
  color: #3dfffc;
}

.text {
  color: #3dfffc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  color: yellow;
  align-items: center;
}

.techStack {
  text-align: center;
}

.description {
  color: white

}