.about {
  background-color: transparent;
  font-size: 1.1em;
  height: 100vh;
  object-fit: cover;
  padding-top: 100px;
  color: white;
}

.profile {
  max-width: 18em;
}