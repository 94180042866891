html {
  scroll-behavior: smooth;
}
body {
background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}