.img-resize {
  width: 10em;
}

.style {
  font-size: 15px;
}

.jumbo-style {
  background-image: linear-gradient(-225deg, #3dfffc 0%, #000 48%, #fffb13 100%);
}

.body-title-style {
  font-size: 15px;
  font-weight: 800;
}